<template>
  <div class="app-meals">
    <meals-top-panel @create-meal="openModal" />
    <meals-table />
    <meals-modal :show.sync="modal.show" :title="modal.title" />
  </div>
</template>

<script>
import MealsTable from './meals-table/meals-table.vue';
import MealsModal from './meals-modal/meals-modal.vue';
import { FETCH_MEALS } from './shared/state/types/action_types';
import MealsTopPanel from './meals-top-panel/meals-top-panel.vue';

export default {
  name: 'Meals',
  components: { MealsTable, MealsModal, MealsTopPanel },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить категорию приема пищи',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(FETCH_MEALS, { page: 1 });
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
  },
};
</script>
